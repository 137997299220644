var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.reconciliationStatus)?_c('div',[_vm._v("-")]):_c('el-tooltip',{staticClass:"d-flex gap-2",attrs:{"disabled":!_vm.statusOverride || (!_vm.isAdmin && !_vm.showOverrideIndication),"placement":"top"}},[(_vm.statusOverride)?_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('reconciliationModal.reconciliationStatusDefineManuallyAs', { status: _vm.$t(`reconciliation.exports.status.${_vm.statusOverride.status}`), }))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('reconciliationModal.definedBy', { user: _vm.statusOverrideModifiedByName, date: _vm.statusOverrideDate }))+" ")])]):_vm._e(),_c('span',[_c('div',{staticClass:"position-relative",class:{ ['ms-1']: _vm.$direction === 'rtl', ['me-1']: _vm.$direction === 'ltr' }},[(_vm.statusOverride && (_vm.isAdmin || _vm.showOverrideIndication))?_c('span',{staticClass:"position-absolute top-50 start-50 translate-middle override-p rounded-circle center border border-2",class:{
            [`border-${_vm.RECONCILIATION_STATUS_COLOR[_vm.statusOverride.status]}`]: true,
          }}):(_vm.statusOverride)?_c('span',{staticClass:"position-absolute top-50 start-50 translate-middle p-1 rounded-circle center",class:{ [`bg-${_vm.RECONCILIATION_STATUS_COLOR[_vm.statusOverride.status]}`]: true }}):_c('span',{staticClass:"position-absolute top-50 start-50 translate-middle p-1 rounded-circle center",class:{
            [`bg-${_vm.RECONCILIATION_STATUS_COLOR[_vm.reconciliationStatus && _vm.reconciliationStatus.status]}`]: true,
          }})]),(_vm.isAdmin)?[_c('StatusOverrideModal',{attrs:{"show":_vm.show,"reconciliation-status":_vm.reconciliationStatus,"reconciliation-id":_vm.reconciliationId,"status-override":_vm.statusOverride,"business-id":_vm.businessId,"supplier":_vm.supplier},on:{"close":function($event){return _vm.$emit('close')}}},[_c('Button',{staticClass:"text-typography-primary p-0",class:{
              disabled: !_vm.hasReconciliationManage,
            },attrs:{"slot":"reference","type":"link","disabled":!_vm.hasReconciliationManage},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('open')}},slot:"reference"},[_c('div',{staticClass:"d-flex gap-1 align-items-center"},[_c('p',{staticClass:"status"},[_vm._v(_vm._s(_vm.calculatedStatus))]),_c('VectorIcon',{staticClass:"vector"}),_c('el-tooltip',{attrs:{"content":_vm.$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.notPermitted'),"placement":"top"}},[(!_vm.hasReconciliationManage)?_c('LockInvertedIcon',{staticClass:"mx-1"}):_vm._e()],1)],1)])],1)]:_c('p',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.calculatedStatus)+" ")])],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }