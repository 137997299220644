export const DOCUMENT_STATUSES = {
  PENDING: 'pending',
  CHECKED: 'checked',
  MISSING: 'missing',
};

export const DOCUMENT_STATUS_COLOR = {
  [DOCUMENT_STATUSES.PENDING]: 'disabled',
  [DOCUMENT_STATUSES.CHECKED]: 'success',
  [DOCUMENT_STATUSES.MISSING]: 'warning',
};

export const MAIN_STATUSES = {
  NOT_APPROVED: 'notApproved',
  PENDING: 'pending',
  APPROVED: 'approved',
};

export const RECONCILIATION_STATUSES = {
  NOT_APPROVED: 'notApproved',
  PENDING: 'pending',
  APPROVED: 'approved',
  WAITING_FOR_MATCH: 'waitingForMatch',
  LOADING: 'loading',
};

export const RECONCILIATION_STATUS_COLOR = {
  [RECONCILIATION_STATUSES.NOT_APPROVED]: 'danger',
  [RECONCILIATION_STATUSES.PENDING]: 'disabled',
  [RECONCILIATION_STATUSES.APPROVED]: 'success',
  [RECONCILIATION_STATUSES.WAITING_FOR_MATCH]: 'warning',
  [RECONCILIATION_STATUSES.LOADING]: 'white',
};

export const RECONCILIATION_TAG_COLOR = {
  [RECONCILIATION_STATUSES.NOT_APPROVED]: 'danger',
  [RECONCILIATION_STATUSES.PENDING]: 'neutral',
  [RECONCILIATION_STATUSES.APPROVED]: 'success',
};

export const BILLING_TABLE_STATUSES = {
  SOLVED_RESULT: 'solvedResult',
  UNSOLVED_RESULT: 'unsolvedResult',
  NETURAL_RESULT: 'neturalResult',

  SOLVED_AMOUNT: 'solvedAmount',
  UNSOLVED_AMOUNT: 'unsolvedAmount',
  NETURAL_AMOUNT: 'neturalAmount',

  SOLVED_QUANTITY: 'solvedQuantity',
  UNSOLVED_QUANTITY: 'unsolvedQuantity',
  NETURAL_QUANTITY: 'neturalQuantity',
};

export const BILLING_TABLE_STATUS_COLOR = {
  [BILLING_TABLE_STATUSES.SOLVED_RESULT]: 'success',
  [BILLING_TABLE_STATUSES.UNSOLVED_RESULT]: 'warning',
  [BILLING_TABLE_STATUSES.NETURAL_RESULT]: 'dark',

  [BILLING_TABLE_STATUSES.SOLVED_AMOUNT]: 'success',
  [BILLING_TABLE_STATUSES.UNSOLVED_AMOUNT]: 'warning',
  [BILLING_TABLE_STATUSES.NETURAL_AMOUNT]: 'dark',

  [BILLING_TABLE_STATUSES.SOLVED_QUANTITY]: 'success',
  [BILLING_TABLE_STATUSES.UNSOLVED_QUANTITY]: 'warning',
  [BILLING_TABLE_STATUSES.NETURAL_QUANTITY]: 'dark',
};

export const BILLING_TYPE = {
  UNBILLED_ORDER: 'unbilled_order',
  UNCERTAIN_BILLING: 'uncertain_billing',
};

export const BILLING_PAYMENT_STATE = {
  NOT_PAYED: 1,
  PARTIALLY_PAYED: 2,
  FULLY_PAYED: 3,
  PAYMENT_IN_PROGRESS: 4,
};

export const INNER_TABLE_ACTIONS = {
  OPEN_BILLING_MODAL: 'openBillingModal',
  RESOLVE_MISSING_EVENT: 'resolveMissingEvent',
};
