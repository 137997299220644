<template>
  <div>
    <div class="d-flex gap-2" :class="{ ['d-none']: !status }">
      <div class="position-relative" :class="{ ['ms-1']: $direction === 'rtl', ['me-1']: $direction === 'ltr' }">
        <span
          class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center"
          :class="{ [`bg-${RECONCILIATION_STATUS_COLOR[status]}`]: true }"
        />
      </div>
      <p>{{ !status ? null : $t(`billing.exports.status.${status}`) }}</p>
    </div>
    <div :class="{ ['d-none']: status }" class="placeholder-glow">
      <span :class="`placeholder bg-secondary rounded ${statusPlaceholderWidth}`" />
    </div>
  </div>
</template>

<script>
import { RECONCILIATION_STATUS_COLOR } from './';

export default {
  props: { status: { type: String, default: null } },
  data() {
    return {
      RECONCILIATION_STATUS_COLOR,
      statusPlaceholderWidth: Math.random() > 0.5 ? 'col-9' : 'col-7',
    };
  },
};
</script>
