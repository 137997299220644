<template>
  <div>
    <div v-if="!reconciliationStatus">-</div>
    <el-tooltip
      v-else
      class="d-flex gap-2"
      :disabled="!statusOverride || (!isAdmin && !showOverrideIndication)"
      placement="top"
    >
      <div v-if="statusOverride" slot="content">
        <div>
          {{
            $t('reconciliationModal.reconciliationStatusDefineManuallyAs', {
              status: $t(`reconciliation.exports.status.${statusOverride.status}`),
            })
          }}
        </div>
        <div>
          {{ $t('reconciliationModal.definedBy', { user: statusOverrideModifiedByName, date: statusOverrideDate }) }}
        </div>
      </div>
      <span>
        <div class="position-relative" :class="{ ['ms-1']: $direction === 'rtl', ['me-1']: $direction === 'ltr' }">
          <span
            v-if="statusOverride && (isAdmin || showOverrideIndication)"
            class="position-absolute top-50 start-50 translate-middle override-p rounded-circle center border border-2"
            :class="{
              [`border-${RECONCILIATION_STATUS_COLOR[statusOverride.status]}`]: true,
            }"
          />
          <span
            v-else-if="statusOverride"
            class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center"
            :class="{ [`bg-${RECONCILIATION_STATUS_COLOR[statusOverride.status]}`]: true }"
          />
          <span
            v-else
            class="position-absolute top-50 start-50 translate-middle p-1 rounded-circle center"
            :class="{
              [`bg-${RECONCILIATION_STATUS_COLOR[reconciliationStatus && reconciliationStatus.status]}`]: true,
            }"
          />
        </div>
        <template v-if="isAdmin">
          <StatusOverrideModal
            :show="show"
            :reconciliation-status="reconciliationStatus"
            :reconciliation-id="reconciliationId"
            :status-override="statusOverride"
            :business-id="businessId"
            :supplier="supplier"
            @close="$emit('close')"
          >
            <Button
              slot="reference"
              type="link"
              class="text-typography-primary p-0"
              :disabled="!hasReconciliationManage"
              :class="{
                disabled: !hasReconciliationManage,
              }"
              @click.stop="$emit('open')"
            >
              <div class="d-flex gap-1 align-items-center">
                <p class="status">{{ calculatedStatus }}</p>
                <VectorIcon class="vector" />
                <el-tooltip
                  :content="$t('document.documentsOverview.tenantDocumentsTable.downloadFiles.notPermitted')"
                  placement="top"
                >
                  <LockInvertedIcon class="mx-1" v-if="!hasReconciliationManage" />
                </el-tooltip>
              </div>
            </Button>
          </StatusOverrideModal>
        </template>
        <p v-else class="status">
          {{ calculatedStatus }}
        </p>
      </span>
    </el-tooltip>
  </div>
</template>

<script>
import { watch } from 'vue';
import { DateTime } from 'luxon';
import { Button } from '@/modules/core';
import { useGlobalPermissions } from '@/modules/permissions';

import { VectorIcon, LockInvertedIcon } from '@/assets/icons';

import { RECONCILIATION_STATUS_COLOR } from '.';
import StatusOverrideModal from './StatusOverrideModal';

export default {
  components: {
    Button,
    VectorIcon,
    LockInvertedIcon,
    StatusOverrideModal,
  },
  props: {
    show: { type: Boolean, default: false },
    isAdmin: { type: Boolean, default: false },
    reconciliationStatus: { type: Object, default: () => null },
    statusOverride: { type: Object, default: () => null },
    supplier: { type: Object, default: null },
    businessId: { type: String, default: null },
    showOverrideIndication: { type: Boolean, default: () => false },
    reconciliationId: { type: String, default: null },
  },
  data() {
    return {
      RECONCILIATION_STATUS_COLOR,
      statusPlaceholderWidth: Math.random() > 0.5 ? 'col-9' : 'col-7',
      hasReconciliationManage: null,
    };
  },
  created() {
    const { hasReconciliationManage } = useGlobalPermissions();
    watch(
      hasReconciliationManage,
      (newVal) => {
        this.hasReconciliationManage = newVal;
      },
      { immediate: true }
    );
  },
  computed: {
    calculatedStatus() {
      const overrideStatus = this.statusOverride?.status;
      if (overrideStatus) {
        return this.$t(`billing.exports.status.${overrideStatus}`);
      }

      const reconciliationStatus = this.reconciliationStatus?.status;
      if (reconciliationStatus) {
        return this.$t(`billing.exports.status.${reconciliationStatus}`);
      }

      return '';
    },
    statusOverrideModifiedByName() {
      return this.statusOverride?.modifiedByDetails
        ? `${this.statusOverride.modifiedByDetails.firstName} ${this.statusOverride.modifiedByDetails.lastName}`
        : null;
    },
    statusOverrideDate() {
      return this.statusOverride?.modifiedAt
        ? DateTime.fromMillis(this.statusOverride.modifiedAt).toFormat('dd.MM.yy, HH:mm')
        : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.vector {
  display: none;
}

.status:hover + .vector {
  display: block;
}

.override-p {
  padding: 3.5px !important;
}

.hidden {
  visibility: hidden;
}

.comment-area {
  height: 67px;
}

::v-deep textarea {
  resize: none;
  height: 67px;
}

.reset-button {
  color: #ff385e;
}

.modified-at {
  color: #9295a5;
}

.popover-wrap {
  width: 220px;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
</style>
