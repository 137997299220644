<template>
  <el-dialog
    visible
    append-to-body
    :show-close="false"
    custom-class="reconciliation-settings-modal"
    @close="$emit('close')"
  >
    <template #title>
      <div class="py-4 rounded-top d-flex justify-content-between">
        <div>
          <h2>
            {{ translate('singleReconciliationSettings') }}
          </h2>
          <p>
            {{ supplier.name }}
            <ExchangeIcon class="mx-1" />
            {{ business.name }}
          </p>
        </div>
        <div>
          <Button type="text" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
            <CloseIcon />
          </Button>
        </div>
      </div>
    </template>
    <div class="border-bottom" />
    <div class="p-5">
      <div class="mb-6">
        <h4 class="pb-4 text-typography-primary">
          {{ translate('createBillingFrom') }}
        </h4>
        <el-select v-model="createBillingFromSelection" :popper-append-to-body="false" class="mt-0">
          <el-option
            v-for="createBillingFromOption in createBillingFromOptions"
            :key="createBillingFromOption.option"
            :label="translate(createBillingFromOption.option)"
            :value="createBillingFromOption.option"
            :disabled="createBillingFromOption.disabled"
          />
        </el-select>
        <p v-if="createBillingFromSelection === 'purchaseOrder'" class="text-typography-secondary mt-2">
          {{ translate('createBillingFromPurchaseOrderInfo') }}
        </p>
      </div>
      <div v-if="createBillingFromSelection === 'billingDocument'" class="mb-6">
        <h4 class="pb-4 text-typography-primary">
          {{ translate('automaticallyCreateOrdersAndDeliveriesFromBilling') }}
        </h4>
        <el-radio-group v-model="autoOrdersFromInvoice">
          <el-radio :label="true">{{ translate('active') }} </el-radio>
          <el-radio :label="false">{{ translate('inactive') }} </el-radio>
        </el-radio-group>
      </div>
      <div v-if="createBillingFromSelection === 'billingDocument'" class="mb-6">
        <h4 class="pb-4 text-typography-primary">
          {{ translate('automaticallyCreateOrdersAndDeliveriesFromCreditNotes') }}
        </h4>
        <el-radio-group v-model="autoOrdersFromCreditNote">
          <el-radio :label="true">{{ translate('active') }} </el-radio>
          <el-radio :label="false">{{ translate('inactive') }} </el-radio>
        </el-radio-group>
      </div>
      <div class="mb-6">
        <h4 class="pb-4 text-typography-primary">{{ translate('balanceAlignmentRequired') }}</h4>
        <el-radio-group v-model="balanceAlignmentRequired">
          <el-radio :label="true">{{ translate('active') }} </el-radio>
          <el-radio :label="false">{{ translate('inactive') }} </el-radio>
        </el-radio-group>
      </div>
      <div v-if="createBillingFromSelection === 'billingDocument'" class="mb-6">
        <h4 class="pb-4 text-typography-primary">{{ translate('supplierWorksWithConsolidatedInvoice') }}</h4>
        <el-radio-group v-model="consolidatedInvoice">
          <el-radio :label="true">{{ $t('commons.yes') }} </el-radio>
          <el-radio :label="false">{{ $t('commons.no') }} </el-radio>
        </el-radio-group>
        <div v-if="consolidatedInvoice" class="mt-1">
          {{ translate('every') }}
          <el-select v-model="consolidatedInvoiceSelection" :popper-append-to-body="false" class="mx-2 w-25">
            <el-option
              v-for="(consolidatedInvoiceValue, index) in consolidatedInvoiceValues"
              :key="index"
              :label="consolidatedInvoiceValue"
              :value="index"
            />
          </el-select>
        </div>
      </div>
      <div class="mb-0">
        <h4 class="text-typography-primary mb-4">{{ translate('note') }}</h4>
        <el-input v-model="note" type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" />
      </div>
    </div>
    <div class="border-bottom" />
    <div class="footer-container">
      <el-button @click="$emit('close')">{{ $t('commons.cancel') }}</el-button>
      <el-button type="primary" :loading="false" @click="handleConfirm"> {{ $t('commons.apply') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ref, getCurrentInstance, computed } from 'vue';
import { isNil } from 'ramda';

import { Button } from '@/modules/core';
import { useTenancy } from '@/modules/auth';
import { CloseIcon, ExchangeIcon } from '@/assets/icons';

export default {
  name: 'ReconciliationSettingsModal',
  components: { CloseIcon, Button, ExchangeIcon },
  props: {
    supplier: { type: Object, required: true },
    business: { type: Object, required: true },
    reconciliationTemplate: { type: Object, required: true },
  },
  setup(props) {
    const { $i18n } = getCurrentInstance().proxy;
    const translate = (key) => $i18n.t(`reconciliation.reconciliationSettings.${key}`);
    const { currentTenant } = useTenancy();

    return {
      translate,
      autoOrdersFromInvoice: ref(props.reconciliationTemplate.autoCreateOrderFromInvoice),
      autoOrdersFromCreditNote: ref(props.reconciliationTemplate.autoCreateOrderFromCreditNote),
      balanceAlignmentRequired: ref(props.reconciliationTemplate.balanceAlignmentRequired),
      consolidatedInvoice: ref(!isNil(props.reconciliationTemplate.consolidatedInvoice) ?? false),
      consolidatedInvoiceSelection: ref(props.reconciliationTemplate.consolidatedInvoice ?? 0),
      consolidatedInvoiceValues: [
        translate('endOfMonth'),
        translate('firstOfMonth'),
        translate('secondOfMonth'),
        translate('thirdOfMonth'),
        translate('forthOfMonth'),
        translate('fifthOfMonth'),
      ],
      note: ref(props.reconciliationTemplate.note ?? ''),
      createBillingFromOptions: computed(() => [
        { option: 'billingDocument', disabled: false },
        { option: 'deliveryDocument', disabled: currentTenant.value.countryCode === 'IL' },
        { option: 'purchaseOrder', disabled: currentTenant.value.countryCode === 'IL' },
      ]),
      createBillingFromSelection: ref(props.reconciliationTemplate.createBillingFrom ?? 'billingDocument'),
    };
  },
  methods: {
    handleConfirm() {
      const billingDocumentSelected = this.createBillingFromSelection === 'billingDocument';
      this.$emit('save', {
        businessId: this.business.id,
        supplierId: this.supplier.id,
        params: {
          autoCreateOrderFromInvoice: billingDocumentSelected ? this.autoOrdersFromInvoice : false,
          autoCreateOrderFromCreditNote: billingDocumentSelected ? this.autoOrdersFromCreditNote : false,
          balanceAlignmentRequired: this.balanceAlignmentRequired,
          consolidatedInvoice: billingDocumentSelected
            ? this.consolidatedInvoice
              ? this.consolidatedInvoiceSelection
              : null
            : null,
          note: this.note,
          createBillingFrom: this.createBillingFromSelection,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

:deep(.reconciliation-settings-modal) {
  width: 664px;
  padding: 0px;
  border-radius: 8px;

  .action-btn {
    height: fit-content;
    &:hover {
      background: $secondary;
    }
  }

  .el-dialog__header {
    padding: 0px 24px;
  }

  .el-dialog__body {
    padding: 0px;
    margin: 0px;
  }
}

.footer-container {
  display: flex;
  justify-content: flex-end;
  padding: 16px 24px;
}

::v-deep .el-scrollbar__wrap {
  overflow: hidden;
}
</style>
