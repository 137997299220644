<template>
  <el-popover :value="show" placement="bottom" trigger="manual" popper-class="mt-4 text-typography-primary ">
    <slot slot="reference" name="reference"></slot>
    <div v-if="show" class="popover-wrap">
      <div class="flex-column gap-1 p-1">
        <h3 class="text-truncate">
          {{ supplier && supplier.name }}
        </h3>
        <p class="text-muted">
          {{
            $t('modals.issues.exports.statusManualChange.reconciliation', {
              date: formatDate(reconciliationStatus && reconciliationStatus.date),
            })
          }}
        </p>
      </div>
      <div class="flex-column gap-1 p-1">
        <p class="fw-bold">
          {{ $t('modals.issues.exports.statusManualChange.calculatedStatus') }}
        </p>
        <div class="d-flex gap-4" :class="{ ['d-none']: !reconciliationStatus }">
          <div class="position-relative ms-1">
            <span
              class="position-absolute top-50 start-52 translate-middle p-1 rounded-circle center"
              :class="{
                [`bg-${RECONCILIATION_STATUS_COLOR[reconciliationStatus && reconciliationStatus.status]}`]: true,
              }"
            />
          </div>
          <p v-if="statusCanBeDisplayed" class="status">
            {{ $t(`billing.exports.status.${reconciliationStatus.status}`) }}
          </p>
        </div>
      </div>
      <hr />
      <div class="flex-column gap-1 p-1">
        <p class="fw-bold">
          {{ $t('modals.issues.exports.statusManualChange.manualStatus') }}
        </p>
        <p v-if="statusOverride" class="modified-at mb-4">
          {{
            $t('modals.issues.exports.statusManualChange.modifiedAt', {
              date: new Date(statusOverride.modifiedAt).toLocaleString($i18n.locale, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              }),
            })
          }}
        </p>
        <el-form ref="form" v-loading="loading" :model="statusManualChangeForm" size="mini">
          <el-form-item prop="status" :rules="rules.status">
            <el-select
              v-model="statusManualChangeForm.status"
              filterable
              autocomplete="on"
              :placeholder="statusPlaceholder"
              :style="{ width: '100%' }"
            >
              <el-option
                v-for="status in MAIN_STATUSES"
                :key="status"
                :value="status"
                :label="generateStatusLabel(status)"
              >
                <div class="d-flex gap-4">
                  <div
                    class="position-relative"
                    :class="{ ['ms-1']: $direction === 'rtl', ['me-1']: $direction === 'ltr' }"
                  >
                    <span
                      class="position-absolute top-50 start-50 translate-middle override-p rounded-circle center border border-2"
                      :class="{ [`border-${RECONCILIATION_STATUS_COLOR[status]}`]: true }"
                    />
                  </div>
                  {{ generateStatusLabel(status) }}
                </div></el-option
              >
            </el-select>
          </el-form-item>
          <div class="w-100">
            <el-form-item prop="comment" :rules="rules.comment">
              <template #label>
                <span class="fw-bold">{{ translate('form.comment') }}</span>
              </template>
              <el-input id="comment" v-model="statusManualChangeForm.comment" class="comment-area" type="textarea" />
            </el-form-item>
          </div>
        </el-form>
      </div>
      <hr />
      <div>
        <div class="d-flex flex-row justify-content-between align-items-center p-1">
          <div>
            <a
              href="javascript:void(0);"
              class="reset-button"
              :class="{ ['hidden']: !statusOverride }"
              :disabled="loading"
              @click="onReset"
            >
              {{ $t('modals.issues.exports.statusManualChange.reset') }}
            </a>
          </div>
          <div>
            <Button :disabled="loading" type="secondary" @click="onCancel">
              {{ $t('modals.issues.exports.statusManualChange.cancel') }}
            </Button>
            <Button :disabled="loading || statusManualChangeForm.status === ''" @click="onApply">
              {{ $t('modals.issues.exports.statusManualChange.apply') }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </el-popover>
</template>

<script>
import { gql } from '@apollo/client/core';
import { isNil } from 'ramda';

import { Button } from '@/modules/core';

import { useStatusOverrideRemove, useStatusOverrideUpdate, MAIN_STATUSES, RECONCILIATION_STATUS_COLOR } from '..';

const MIN_COMMENT_LENGTH = 20;

export default {
  components: {
    Button,
  },
  props: {
    show: { type: Boolean, default: false },
    reconciliationStatus: { type: Object, default: () => null },
    statusOverride: { type: Object, default: () => null },
    supplier: { type: Object, default: null },
    businessId: { type: String, default: null },
    reconciliationId: { type: String, default: null },
  },
  setup() {
    const { mutate: updateManualStatus } = useStatusOverrideUpdate();
    const { mutate: removeManualStatus } = useStatusOverrideRemove();
    return {
      updateManualStatus,
      removeManualStatus,
    };
  },
  data(props) {
    return {
      RECONCILIATION_STATUS_COLOR,
      MAIN_STATUSES,
      loading: false,
      statusManualChangeForm: {
        comment: props.statusOverride?.comment ?? '',
        status: props.statusOverride?.status ?? '',
      },
    };
  },
  computed: {
    statusCanBeDisplayed() {
      return !isNil(this.reconciliationStatus);
    },
    rules() {
      return {
        status: [
          {
            required: true,
            message: this.translate('inputs.rules.status.empty'),
          },
        ],
        comment: [
          {
            required: true,
            type: 'string',
            message: this.$t(`modals.issues.exports.statusManualChange.inputs.rules.comment.empty`, {
              minLength: MIN_COMMENT_LENGTH,
            }),
          },
          {
            validator: (_, value, callback) => {
              if (value.length < MIN_COMMENT_LENGTH)
                return callback(
                  new Error(
                    this.$t(`modals.issues.exports.statusManualChange.inputs.rules.comment.empty`, {
                      minLength: MIN_COMMENT_LENGTH,
                    })
                  )
                );
              callback();
            },
          },
        ],
      };
    },
    statusPlaceholder() {
      const status = this.statusOverride?.status ?? this.reconciliationStatus?.status;
      return this.generateStatusLabel(status);
    },
  },
  watch: {
    statusOverride(newStatusOverride) {
      this.$nextTick(() => this.$refs.form?.clearValidate(['status', 'comment']));
      this.statusManualChangeForm.status = newStatusOverride?.status ?? '';
      this.statusManualChangeForm.comment = newStatusOverride?.comment ?? '';
    },
  },
  methods: {
    formatDate(month) {
      if (!month) return '';
      return new Date(month).toLocaleString(this.$i18n.locale, { month: 'long', year: 'numeric' });
    },
    translate(key) {
      return this.$t(`modals.issues.exports.statusManualChange.${key}`);
    },
    generateStatusLabel(status) {
      if (!status) {
        return '';
      }
      return this.$t(`billing.exports.status.${status}`);
    },
    onApply() {
      this.$refs.form.validate(async (isValid) => {
        if (isValid) {
          this.loading = true;
          try {
            await this.updateManualStatus(
              {
                reconciliationId: this.reconciliationId,
                params: {
                  ...this.statusManualChangeForm,
                },
              },
              {
                update: (cache, { data: { reconciliationStatusOverrideUpdate: statusOverride } }) => {
                  cache.writeFragment({
                    id: `Reconciliation:${this.reconciliationId}`,
                    data: { __typename: 'Reconciliation', statusOverride },
                    fragment: gql`
                      fragment statusOverride on Reconciliation {
                        statusOverride {
                          status
                          comment
                          modifiedAt
                          modifiedByDetails {
                            firstName
                            lastName
                          }
                          __typename
                        }
                      }
                    `,
                  });
                },
              }
            );
            this.$message.success(this.$t('commons.messages.action.success'));
          } catch (error) {
            this.$message.error(this.$t('commons.messages.action.error'));
          } finally {
            this.loading = false;
            this.$emit('close');
          }
        }
      });
    },
    onCancel() {
      this.$emit('close');
    },
    async onReset() {
      this.loading = true;
      try {
        await this.removeManualStatus(
          {
            reconciliationId: this.reconciliationId,
          },
          {
            update: (cache) => {
              cache.writeFragment({
                id: `Reconciliation:${this.reconciliationId}`,
                data: { __typename: 'Reconciliation', statusOverride: null },
                fragment: gql`
                  fragment statusOverride on Reconciliation {
                    statusOverride {
                      status
                    }
                  }
                `,
              });
            },
          }
        );
        this.$message.success(this.$t('commons.messages.action.success'));
      } catch (error) {
        this.$message.error(this.$t('commons.messages.action.error'));
      } finally {
        this.loading = false;
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.vector {
  display: none;
}

.status:hover + .vector {
  display: block;
}

.override-p {
  padding: 3.5px !important;
}

.hidden {
  visibility: hidden;
}

.comment-area {
  height: 67px;
}

::v-deep textarea {
  resize: none;
  height: 67px;
}

.reset-button {
  color: #ff385e;
}

.modified-at {
  color: #9295a5;
}

.popover-wrap {
  width: 220px;
}
</style>
